h1 {
  font-size: 1.875rem;
}

input,
textarea {
  &.ant-input {
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
  }

  &.ant-input:focus,
  &.ant-input-focused {
    border-bottom-color: $color-labels-active;
    box-shadow: none;
    -webkit-box-shadow: unset;
  }

  border-bottom: 0.0625rem solid $color-input-border-not-active;
}

input:focus {
  border-color: $color-txt-black;
}

textarea {
  resize: none;
  overflow: hidden;
  color: $color-txt-black;
}

.input-text input {
  border-bottom: 0.0625rem solid $color-input-border-not-active;
}

input,
textarea {
  &:focus {
    border-bottom-color: $color-labels-active;
  }
}

.ant-input-number {
  input,
  input::placeholder {
    color: $color-txt-black;
  }
}

body {
  font-size: 1rem;
  background-color: $color-background-block;

  h1 {
    margin-left: 2rem;
  }

  h3 {
    font-size: 1.3125rem;
  }

  input {
    font-size: 1.0625rem;
    outline: unset;
    color: $color-txt-black;

    @include default-bottom-border;

    color: $color-input-border-not-active;

    &:focus {
      border-bottom-color: $color-labels-active;
      color: $color-labels-active;
      outline: none;
    }
  }

  label {
    color: $color-labels-txt;
    font-size: 0.8125rem;
  }

  .max-width {
    width: 100%;
  }

  .textAlignRight {
    text-align: right;
  }

  .textAlignCenter {
    text-align: center;
  }

  .ant-select,
  .ant-picker-input,
  .ant-picker-input-active,
  .ant-picker-input > input {
    color: $color-txt-black;
    font-size: 0.9375rem;

    ::-webkit-input-placeholder {
      /* Edge */
      color: $color-txt-black;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-txt-black;
    }

    ::placeholder {
      color: $color-txt-black;
    }
  }

  .ant-select-open {
    border-bottom-color: $color-labels-active;
    color: $color-labels-active;
  }

  font-family: Roboto;

  .align-right {
    text-align: right;
  }

  // Table styles.
  .ant-table {
    line-height: 1.4rem;
  }

  .ant-table,
  table {
    thead {
      > tr {
        > th {
          background-color: inherit;
          font-size: 0.6875rem;
          color: $color-labels-txt;
          text-overflow: unset;
          white-space: break-spaces;
          line-height: 1rem;
        }
      }
    }

    tbody,
    tfoot {
      > tr {
        td {
          padding: 1rem 0;
        }
      }
    }

    .expandable-row {
      cursor: pointer;
    }
  }

  .ant-table-row.status-PAID,
  .ant-table-row.status-UNPAID {
    transform: scale(1);

    &.status-PAID::after {
      background-color: $color-border-invoice-status-paid;
    }

    &.status-UNPAID::after {
      background-color: $color-contract-overdue-txt;
    }

    &::after {
      content: "";
      position: absolute;

      width: 0.5rem;
      height: calc(100% + 3px);
      right: -0.5rem;
      top: -2px;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    &.expand-parent::after {
      border-bottom-right-radius: unset;
    }
  }

  .ant-table-expanded-row.status-UNPAID td.ant-table-cell {
    position: relative;
    .expanded-report-item.isExpanded::after {
      content: "";
      position: absolute;
      background-color: $color-contract-overdue-txt;
      width: 0.5rem;
      height: calc(100% + 3px);
      right: -0.5rem;
      top: -2px;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .ant-table-expanded-row.status-PAID td.ant-table-cell {
    position: relative;
    .expanded-report-item.isExpanded::after {
      content: "";
      position: absolute;
      background-color: $color-border-invoice-status-paid;
      width: 0.5rem;
      height: calc(100% + 3px);
      right: -0.5rem;
      top: -2px;
      border-bottom-right-radius: 0.5rem;
    }
  }

  .ant-table-pagination.ant-pagination,
  .grid-pagination {
    margin-top: 2.5rem;
  }

  .ant-table-pagination,
  .grid-pagination {
    text-align: center;

    .ant-pagination-prev .ant-pagination-item-link,
    .ant-pagination-next .ant-pagination-item-link {
      background-color: transparent;
    }

    li {
      margin-right: 0;
      border-left: unset;
      border-right: unset;
      height: auto;
      border-radius: unset;

      border-top: 0.0625rem $color-pagination-border solid;
      border-bottom: 0.0625rem $color-pagination-border solid;

      &.ant-pagination-prev {
        border-left: 0.0625rem $color-pagination-border solid;
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        height: 2.5625rem;
      }

      &.ant-pagination-next {
        border-right: 0.0625rem $color-pagination-border solid;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        height: 2.5625rem;
      }

      &.ant-pagination-prev,
      &.ant-pagination-next {
        a {
          border: unset;
          padding: 0.2rem 1.5rem;
        }
      }

      a {
        font-size: 1rem;
        padding: 0.3rem;
        color: $color-pagination-border;
      }

      &.ant-pagination-item {
        line-height: 1.8125rem;
      }

      &.ant-pagination-jump-next,
      &.ant-pagination-jump-prev {
        height: 2.5625rem;
      }

      .ant-pagination-item-link {
        .ant-pagination-item-container {
          bottom: 1.6875rem;
        }
      }

      .ant-pagination-item-link-icon {
        position: relative;
        bottom: 0.125rem;
      }

      &.ant-pagination-item-active {
        border-color: $color-pagination-border;

        a {
          color: $color-pagination-active-txt;
        }
      }

      &:hover {
        border-color: $color-pagination-border;

        a {
          color: $color-pagination-active-txt;
        }
      }

      &.ant-pagination-options {
        margin-left: 0;
        border-left: 0.0625rem $color-pagination-border solid;
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        border-right: 0.0625rem $color-pagination-border solid;
        border-top-right-radius: 2rem;
        border-bottom-right-radius: 2rem;
        color: $color-pagination-border;

        input,
        .ant-select-selection-item {
          color: $color-pagination-border;
        }

        > .ant-select {
          margin: 0;
          padding: 0.2rem 1rem;

          .ant-select-selector {
            background-color: transparent;
          }
        }

        .ant-select-arrow {
          right: 1.5rem;
        }
      }
    }
  }

  .ant-table-wrapper {
    background-color: $color-background;
    margin: 0.9375rem 0;
    padding: $table-table-wrapper-padding;
    font-family: Roboto;

    &.hasCollapsingRows {
      padding-left: 0;

      tr td.ant-table-row-expand-icon-cell {
        > div.arrowIconWrap {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span.anticon.anticon-right {
          transition: transform 0.3s ease-out;
          &.rotateDown {
            transform: rotateZ(90deg);
          }
          svg {
            font-size: 0.625rem;
          }
        }
      }

      tr.expand-parent + tr td {
        padding-bottom: 1rem;
        border-bottom: 0.0625rem solid $color-tables-border-not-active;
      }

      ul.ant-pagination {
        padding-left: $table-table-wrapper-padding;
      }

      tr.ant-table-expanded-row {
        &:before {
          content: "";
          width: $table-table-wrapper-padding;
        }

        td {
          padding: 0;
          border: none;
        }

        div.expanded-report-item {
          transition: height 0.3s ease-out;
          overflow: hidden;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          &.notExpanded {
            > table {
              border-top: none;
            }
          }

          > table {
            width: unset;
            border-top: 0.0625rem solid $color-tables-border-not-active;
            padding-top: 1rem;
            table-layout: fixed;

            tr {
              border: none;

              &:not(:last-child) td {
                padding-bottom: 0.3125rem;
              }

              td {
                color: $color-secondary-text;
                padding-top: 0;
                padding-bottom: 0;
                border: none;
                &:not(:last-child) {
                  padding-right: 1.25rem;
                }
                &:first-child {
                  padding-left: 1rem;
                }
              }
            }
          }
        }
      }

      &.noData {
        padding-left: 4rem;
      }
    }

    .ant-table-thead {
      > tr {
        border-bottom: none;

        > th {
          color: $color-labels-txt;
          background-color: $color-background;
          font-size: 0.6875rem;
          @include border-bottom-tables;
        }
      }
    }

    .ant-table-tbody {
      > tr {
        > td {
          color: $color-labels-txt;
          background-color: $color-background;
          color: $color-txt-black;
          font-size: 0.9375rem;

          @include border-bottom-tables;

          &.linkButton {
            width: 2rem;
          }
          // outside .linkButton to use in expanded
          div.downloadBtnWrap {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            align-self: center;
            > button.downloadBtn {
              display: inline-block;
              padding: unset;
              width: 1rem;
              height: 0.9375rem;
              overflow: hidden;
              text-indent: 9999rem;
              background-image: url("./../icons/icon_download.svg");
              background-repeat: no-repeat;
              background-position: top center;
              transform: rotate(180deg);
              margin-right: 1rem;
            }
          }

          a {
            color: inherit;
          }
        }
      }
    }
  }

  table {
    tbody,
    tfoot {
      tr {
        border-top: 0.0625rem solid $color-tables-border-not-active;
      }
    }
    tr {
      border-bottom: 0.0625rem solid $color-tables-border-not-active;

      th {
        padding-bottom: 0.8125rem;
      }

      td {
        color: $color-txt-black;
        font-size: 0.9375rem;

        a {
          color: inherit;
          font-size: inherit;
        }
      }
    }
  }

  .ant-table {
    thead {
      th.ant-table-column-sort,
      .ant-table-cell:hover {
        background: none;
      }

      .ant-table-column-sorter-up.active,
      .ant-table-column-sorter-down.active {
        color: $color-sme-blue;
      }
    }
  }
  // End of table styles.

  .form-control {
    font-size: 0.9375rem;
    float: right;

    .ant-btn {
      float: right;
      border: unset;
      box-shadow: unset;
      padding: 0;
      margin: 0;
      color: $color-sme-input-icons;
      font-size: 0.9375rem;
      height: auto;
    }
  }

  .border-right {
    > div {
      border-right: 0.0625rem solid $color-border-right;
    }
  }

  .grayBg {
    background-color: $color-background-block;
  }

  .noBg {
    background-color: transparent;
    .block {
      background-color: transparent;
    }
  }
}

//switch.
.ant-switch {
  background-color: $color-tables-border-not-active;

  &::after {
    background-color: $color-input-border-not-active;
  }
}

.ant-switch-checked {
  background-color: $color-switch-circle;

  &::after {
    background-color: $color-switch-cheched;
  }
}

//end of switch.

//modal.

.ant-modal-content {
  width: 48.125rem;

  .ant-badge-count {
    background-color: $color-upload-error;
    width: 1.5625rem;
    height: 1.5625rem;
    border-radius: 0.78125rem;
    font-size: 0.9375rem;
    padding: 0;
  }
}

.ant-modal-close-x {
  display: none;
}

.ant-modal-header {
  border-bottom: 0 none;
}

.ant-modal-header {
  text-align: center;
  padding-top: 1.875rem;

  .ant-modal-title {
    font-size: 1.5625rem;
    font-weight: normal;
  }
}

.ant-modal-body {
  padding-left: 7.125rem;
}

//end of modal.

//Select.
.ant-select {
  .anticon-down {
    background-image: url(/static/media/icon_arrow.5e1660f6.svg);
    width: 0.8115rem;
    height: 0.43625rem;

    background-repeat: no-repeat;

    svg {
      display: none;
    }
  }
}

//End of select.

.ContractFilter,
.ContractSelect {
  border-radius: 0.5rem;
  overflow: hidden;
  border: unset;
  background-color: $color-background;
  color: $color-txt-black;
  font-size: 0.9375rem;

  &:not(.contractFilterSingle) div.headerMenu button {
    transform: translateY(-50%);
  }

  .ant-row {
    .ant-col {
      @include default-bottom-border;
      border-bottom-color: $color-tables-border-not-active;
      padding: 1rem 0;

      &:first-child {
        padding-left: 2.2rem;

        &.ant-col-0 + .ant-col {
          padding-left: 2.2rem;
        }
      }
    }
  }

  .headerMenu {
    position: relative;
    .ant-row {
      .ant-col {
        border-bottom: unset;
      }
    }
  }

  button {
    position: absolute;
    right: 0.9rem;
    top: 1.6rem;
    background-color: transparent;
    border: none;
    background-image: url("./../icons/icon_arrow.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 2rem;
    height: 2rem;
  }

  .contractsSelectMenu {
    overflow: hidden;
    display: none;
    border-radius: 0.5rem;
    top: 0.8rem;
    padding: 0.375rem 0;

    > button + .ant-row {
      @include border-top;
      border-color: $color-tables-border-not-active;
    }

    &.active-true {
      display: block;
      position: absolute;
      background-color: $color-background;
      width: 100%;
      z-index: 2;
      //top: 0.8rem;
      @include box-shaddow-thin;

      button {
        top: 0.75rem;
        transform: rotate(180deg);
        z-index: 1;
      }

      .ant-row {
        width: 74rem;
        cursor: pointer;

        &:hover {
          .ant-col {
            background-color: $color-background;
          }
        }

        .ant-col {
          padding: 0.719rem 0.3125rem 0.719rem 0;

          &:last-child {
            padding-right: 2.8rem;
          }
        }
      }
    }
  }

  > ul {
    z-index: 1;

    li {
      font-size: 0.9375rem;
      color: $color-txt-black;
    }
  }

  label {
    margin-left: 0;
    font-size: inherit;
  }

  .not-selected {
    //background-color: $color-background-block;
    .ant-col {
      background-color: $color-background-block;
    }
  }
}

.ContractFilter,
body .invoices-container > div.ContractFilter,
.Bills-page .ContractFilter,
.Reports-page .ContractFilter {
  &.single-item {
    cursor: default;
  }
}

.noPaddings {
  padding: 0;
}

td.linkButton {
  width: 2rem;
  text-align: right;

  a {
    display: inline-block;
    width: 1rem;
    height: 0.9375rem;
    overflow: hidden;
    text-indent: 9999rem;
    background-image: url("./../icons/icon_download.svg");
    background-repeat: no-repeat;
    background-position: top center;
    transform: rotate(180deg);
    margin-right: 1rem;
  }
}

.Reports-page {
  .input-search-wrapper {
    > span {
      padding-top: 0.4rem;
    }
  }

  .filterDataValue {
    color: $color-sme-input-icons;
    float: right;
  }
  .ContractSelector {
    padding: 0;
    cursor: pointer;

    h4 {
      display: none;
    }
  }

  div.ContractFilter {
    padding: 0;
  }

  .ReportListFilter {
    padding: 2rem 4rem 3.3rem 4rem;
    margin-top: 0.9375rem;

    .checboxFilters {
      label {
        font-size: 0.9375rem;
        color: $color-txt-black;
        border: 0.0625rem $color-report-checkbox-border solid;
        border-radius: 2rem;
        padding: 0.75rem 1.4rem;
        padding-top: 0.7rem;
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
        cursor: pointer;
        min-width: 12rem;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active-true {
          background-color: $color-report-checkbox-checked-bg;
          border: unset;

          .filterDataValue {
            color: $color-txt-black;
          }
        }
      }

      input {
        display: none;
      }
    }

    label {
      font-size: 0.6875rem;
    }

    input {
      margin: 0.2rem 0;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: $color-labels-active;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-labels-active;
    }

    ::placeholder {
      color: $color-labels-active;
    }

    .ant-select-selector {
      padding: 0;
      width: 100%;
    }

    input {
      color: $color-labels-active;
    }

    .ant-select {
      width: 100%;
    }

    .ant-form-item,
    .ant-picker .ant-picker-input,
    .ant-select-selection-item {
      @include default-bottom-border;
      margin-right: 2rem;
    }

    .ant-select-selection-placeholder {
      opacity: 1;
    }

    .ant-form-horizontal > .ant-row:last-child .ant-select-selection-placeholder {
      @include default-bottom-border;
      margin-right: 2rem;
    }

    .ant-col.filled {
      .ant-form-item,
      .ant-picker .ant-picker-input,
      .ant-select-selection-item {
        border-bottom-color: $color-labels-active;
        color: $color-labels-active;
      }

      .ant-form-horizontal > .ant-row:last-child .ant-select-selection-placeholder {
        border-bottom-color: $color-labels-active;
        color: $color-labels-active;
      }
    }

    .ant-select-single .ant-select-arrow {
      margin-right: 1.3rem;
    }

    .ant-form-horizontal .ant-row:last-child .ant-select-single {
      margin-top: 0.09rem;
    }

    .ant-form-horizontal .ant-row:first-child .ant-select-single .ant-select-selector,
    .ant-form-horizontal .ant-row:first-child .ant-col-6:first-child .ant-select-selection-item {
      border-bottom: unset;
    }

    .ant-form-horizontal .ant-row:first-child .ant-col-6:first-child .ant-select-arrow {
      margin-right: -0.7rem;
    }

    .ant-picker {
      padding: 0.6rem 0;
    }

    .ant-checkbox-input,
    .ant-checkbox-inner {
      display: none;
    }
  }
}

.Bills-page {
  .BillListFilter {
    padding: 2rem 4rem 4rem 4rem;

    .active-true {
      .filterDataValue {
        color: $color-txt-black;
      }
    }

    .ant-select-selection-item {
      border-bottom: unset;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      border-bottom: 0.0625rem solid $color-input-border-not-active;
    }
    .checboxFilters {
      margin-bottom: 1.25rem;

      label {
        min-width: 16.1rem;
      }
    }
  }
  .ContractSelector {
    padding: 0;

    h4 {
      display: none;
    }

    .contractsSelectMenu {
      margin-top: -0.1875rem;
    }
  }

  .filterDataValue {
    color: $color-sme-input-icons;
    float: right;
  }
  ::placeholder {
    color: $color-labels-active;
  }
  .ReportListFilter {
    label {
      font-size: 0.6875rem;
    }

    .ant-select-selector,
    .ant-picker,
    .ant-picker-input {
      padding: 0;
      width: 100%;
    }

    input {
      font-size: 0.9375rem;
    }
    .ant-select-single {
      width: 96%;
    }

    .ant-select-selector {
      margin-top: 0.113rem;
    }

    .ant-input-search {
      padding: 0;
    }

    .ant-picker,
    .ant-input-search {
      padding-top: 0.2rem;
    }

    .ant-picker .ant-picker-input,
    .ant-form-item {
      border-bottom: 0.0625rem solid $color-input-border-not-active;
    }

    .ant-picker .ant-picker-input,
    .ant-select-selection-item,
    .ant-form-item,
    .input-seach,
    .ant-select-single {
      margin-right: 2rem;
    }

    input {
      margin: 0.2rem 0;
    }
  }

  .checboxFilters {
    label {
      font-size: 0.9375rem;
      color: $color-txt-black;
      border: 0.0625rem $color-report-checkbox-border solid;
      border-radius: 2rem;
      padding: 0.75rem 1.4rem;
      padding-top: 0.7rem;
      margin-left: 0.5rem;
      cursor: pointer;
      min-width: 12.926rem;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }

      &.active-true {
        background-color: $color-report-checkbox-checked-bg;
        border: unset;
      }
    }
    input {
      display: none;
    }
  }

  .ant-table-wrapper {
    padding-top: unset;
  }

  .block {
    margin: 0.9375rem 0;
  }

  .billList {
    .innerAmount {
      position: relative;
    }

    margin: 0;

    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;
  }

  .BillTable {
    td.ant-table-row-expand-icon-cell,
    th.ant-table-row-expand-icon-cell {
      border: none;
      width: $table-table-wrapper-padding;
      max-width: $table-table-wrapper-padding;
      min-width: $table-table-wrapper-padding;
    }
    .invoiceAmount {
      width: 6rem;
    }

    .innerItemDate {
      padding-left: 1.25rem;
    }

    .paid,
    .unpaid {
      width: 6rem;
    }

    .downloadBtn {
      display: inline-block;
      width: 1rem;
      height: 0.9375rem;
      overflow: hidden;
      text-indent: 9999rem;
      background-image: url(/static/media/icon_download.659a9e91.svg);
      background-repeat: no-repeat;
      background-position: top center;
      transform: rotate(180deg);
      margin-right: 1rem;
    }

    .ant-btn-link {
      margin-right: 1rem;
      height: 0.9375rem;
      padding: unset;
      line-height: 1.5715;
      margin-top: 0.125rem;
    }

    .ant-table-cell {
      &.billStatus {
        border-bottom: unset;
        width: 0.4375rem;

        &:after {
          display: block;
          position: absolute;
          width: 0.5rem;
          content: "";
          height: 100%;
          top: -0.0625rem;
          border-right: 0.5rem solid #fff;
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }

    th {
      padding-left: 0;
    }

    .anticon-right,
    .anticon-down {
      font-size: 0.6rem;
    }
  }

  .ant-table-wrapper {
    .expand-parent td {
      border-bottom: 0 none;
      border-bottom-right-radius: unset;
    }

    .expand-parent {
      .billStatus:after {
        border-bottom-right-radius: unset;
      }
    }

    .status-Paid:after,
    + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-border-invoice-status-paid;
    }

    .status-Unpaid:after,
    .status-Unpaid + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-contract-overdue-txt;
    }

    .status-Sold:after,
    .status-Writtenoff:after,
    .status-Sold + .ant-table-expanded-row-level-1 td:after,
    .status-Writtenoff + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-is-rejected;
    }

    .expand-parent + .ant-table-expanded-row-level-1 td:after {
      border-top-right-radius: unset;
    }

    .status-Notsubmitted + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-not-submitted;
    }

    .status-Overdue + .ant-table-expanded-row-level-1:after {
      border-right-color: $color-invoice-overdue;
    }

    .status-Verification + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-verification;
    }

    .status-Rejected + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-rejected;
    }

    .status-Submitted + .ant-table-expanded-row-level-1 td:after {
      border-right-color: $color-invoice-submitted;
    }
  }
}

// Payment statuses.
.ant-table-cell {
  &.status {
    border-right: 0.5rem solid $color-invoice-default;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    width: 0.5rem;
    padding: 0;
  }

  &.status-Paid,
  &.status-Paid::after {
    border-right-color: $color-invoice-paid;
  }

  &.status-Notsubmitted,
  &.status-Notsubmitted::after {
    border-right-color: $color-invoice-not-submitted;
  }

  &.status-Overdue,
  &.status-Overdue::after {
    border-right-color: $color-invoice-overdue;
  }

  &.status-Verification,
  &.status-Verification::after {
    border-right-color: $color-invoice-verification;
  }

  &.status-Rejected,
  &.status-Rejected::after {
    border-right-color: $color-invoice-rejected;
  }

  &.status-Submitted,
  &.status-Submitted::after {
    border-right-color: $color-invoice-submitted;
  }

  &.status-Unpaid,
  &.status-Unpaid::after {
    border-right-color: $color-contract-overdue-txt;
  }
}

.ant-input-group {
  .ant-input-group-addon {
    .ant-select-show-arrow {
      width: 3.3rem;
    }
  }
}

// Forms.
.ant-form-item {
  &.ant-form-item-has-success {
    border-bottom-color: $color-labels-active;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      border-bottom: unset;
    }
  }
}
// End of forms.

//upload drag.
.ant-upload {
  &.ant-upload-drag {
    border-color: $color-sme-input-icons;
    background: transparent;
    border-radius: 6.25rem;
    margin-right: 1.875rem;
    width: auto;
  }
}

.ant-upload-list-item {
  .anticon-close {
    color: $color-close;
    opacity: 1;
  }
}

.ant-upload-list-item-card-actions {
  opacity: 1;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}

.ant-tooltip-inner {
  color: $color-txt-black;
  background-color: $color-background;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: transparent;
}

.ant-tooltip-arrow {
  display: none;
}

.ant-upload-list-item-name {
  pointer-events: none;
}
//End of Upload drag.

//Status notifications.
.ant-notification-notice {
  .ant-notification-notice-with-icon {
    svg {
      color: inherit;
    }
  }
}

//End of Status notifications.
.disabled-menu-item {
  pointer-events: none;
  opacity: 0.4;
}

.white-bg {
  background-color: $color-background;
}
.ant-layout-content {
  padding-bottom: 10rem;
}

.noContracts {
  position: absolute;
  top: 50%;
  left: 50%;
}

.pageNotFound {
  position: absolute;
  min-width: 105rem;
  width: 100%;
  left: 0;
  top: 0;
  background-image: url("./../images/redbg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;

  footer {
    padding-bottom: 6.6875rem;
  }

  .headerContent {
    margin-top: 2.8125rem;
  }

  .header404 {
    min-width: 97.1875rem;
    height: 34.375rem;
    width: 100%;
    left: 0;
    top: 0;

    .pageNotFoundMsg {
      display: block;
      width: 38.5rem;
      height: 23.125rem;
      margin: 0 auto;
      margin-top: 5rem;
      font-size: 3.215625rem;
      color: $color-white-txt;
      background-image: url("./../images/page-not-found-msg.png");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: contain;
      text-align: center;
      padding-top: 8.5rem;
    }
  }

  .content404 {
    margin-top: 9.25rem;

    > .ant-col {
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 1.3125rem;
      color: $color-txt-black-notfound;
    }

    .ant-btn {
      margin: 0 auto;
      margin-top: 2.4375rem;
      margin-bottom: 3.75rem;

      > span {
        margin-top: -9px;
        display: flex;
      }
    }
  }
}

// Shadows for blocks.
.dashboard-container,
.invoices-container,
.Reports-page,
.Bills-page,
.contractDetailView {
  .ContractSelector,
  .DonutPie,
  .block-upcomingPayments,
  .ContractFilter,
  .InvoiceListFilter,
  .invoiceTable,
  .reportTable,
  .billList,
  .invoiceStatusCarpet {
    @include box-shaddow-thin;
  }
}

.contractListCollapse {
  @include box-shaddow-thin;
  border-radius: 0.625rem;
}

.invoiceUpload {
  > .ant-spin-nested-loading,
  .steps-action,
  > .steps-content,
  .selectedContract,
  .uploadDocuments,
  .uploadSummary,
  .uploadDocumentList {
    @include box-shaddow-thin;
  }
}

.account-container {
  > div > div {
    @include box-shaddow-thin;
  }
}

.box-shadow {
  @include box-shaddow-thin;
}

body .ant-pagination.ant-table-pagination li.ant-pagination-options .ant-select-selection-item,
.grid-pagination li.ant-pagination-options .ant-select-selection-item {
  color: $color-txt-black;
}

.ant-pagination-options {
  .ant-select-dropdown {
    position: absolute;
    margin-top: -5rem;
    margin-left: -0.125rem;
    @include box-shadow;
    border-radius: 0.625rem;

    .ant-select-item-option {
      color: $color-txt-black;
      background-color: #f9f9f9;
      border-top: 0.0625rem solid $color-tables-border-not-active;
      font-weight: normal;

      &:first-child {
        border-top: unset;
      }

      &:hover {
        background-color: $color-background;
      }
    }

    .ant-select-item-option-selected {
      background-color: $color-background;
    }
  }

  .ant-pagination-options-size-changer {
    .ant-select-arrow + div > div > div {
      margin-top: -0.6875rem;
    }
  }
}

.customForm {
  input,
  select,
  .ant-input-suffix {
    border-bottom: 0.0625rem solid $color-input-border-not-active;
    font-size: 1.0625rem;
  }

  .ant-input-suffix {
    margin-left: unset;
  }

  .ant-input-affix-wrapper {
    padding-right: unset;
    padding-left: unset;
  }

  .ant-select {
    margin-right: 0.625rem;
    border-bottom: 0.0625rem solid $color-input-border-not-active;
  }

  .ant-input-group-addon {
    border: 0 none;
    background: transparent;
    padding-top: 0.125rem;
    padding-right: 0;
  }

  h3 {
    font-size: 1.0625rem;
    color: $color-labels-txt;
    font-weight: 300;
    margin-top: 1.25rem;
  }

  .ant-select-selector {
    padding-left: 0;
    margin-top: 0.125rem;
    font-size: 1.0625rem;
  }

  .ant-form-item {
    min-width: auto;
    padding-right: 0.625rem;
  }
}

.ant-tabs-tab-arrow-show {
  display: none;
}

.ant-select-item {
  font-size: 0.9375rem;
}

.ant-form-item-has-error {
  .ant-form-item-explain {
    color: $color-error;
    font-size: 0.8125rem;
  }
}
