.invoiceUpload .uploadDocuments .ant-form .supportingDocuments .ant-form-item-control-input {
  border-bottom: 0 none;
  padding-right: unset;
}

body .invoices-container {
  .totalInvoiceDataWrapper {
    margin-bottom: unset;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 4rem;
    padding-bottom: unset;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;

    .export {
      float: right;
      margin-right: 2rem;
      padding: unset;
      height: 0;
      color: $color-sme-input-icons;
      font-size: 0.9375rem;
    }

    .export-icon {
      padding-top: 0.4375rem;
      background-image: url("./../icons/icon_export.png");
      background-repeat: no-repeat;
      width: 1.625rem;
      height: 1.4375rem;
      vertical-align: middle;
      margin-left: 0.25rem;
    }

    .totalInvoiceData {
      margin: unset;
      padding-left: unset;
      padding-right: unset;
    }
  }

  .totalInvoiceData {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 59.1875rem;

    th {
      padding-left: 0;
      padding-right: unset;
    }

    > * {
      padding-right: unset;
    }

    > .ant-spin-nested-loading > .ant-spin-container > .ant-table {
      width: 65.525rem;
    }

    .ant-pagination {
      display: none;
    }

    .totalColumn {
      width: 31.7rem;
    }

    .totalInvoiceAmt {
      width: 6.8rem;

      .ant-col:not(:first-child) {
        position: absolute;
      }
    }

    .totalFinanced {
      width: 6rem;
    }

    .totalRemainder {
      width: 6rem;
    }
  }

  .ant-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.buyer {
      min-width: 11rem;
      max-width: 11rem;
      width: 11rem;
    }
    &.invoiceNumber {
      min-width: 8.1875rem;
      max-width: 8.1875rem;
      width: 8.1875rem;
    }

    &.invoiceDate,
    &.invoiceDueDate {
      min-width: 6.25rem;
      max-width: 6.25rem;
      width: 6.25rem;
    }

    &.advanceAmountTotal,
    &.remainder {
      min-width: 6rem;
      max-width: 6rem;
      width: 6rem;
    }

    &.toBePaid {
      min-width: 6rem;
      max-width: 7.4rem;
      width: 7.4rem;
    }

    &.invoiceAmount {
      width: 7rem;
      max-width: 7rem;
    }

    &.invoiceStatusTxt {
      width: 6.5rem;
      max-width: 6.5rem;
      text-transform: capitalize;
    }
  }

  .invoiceTable {
    margin-top: 0;
    padding-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    th {
      padding-left: 0;
      text-overflow: unset;
      white-space: break-spaces;
      line-height: 1rem;

      &.invoiceStatusCell {
        padding-right: 0;
      }
    }

    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  .ant-table-cell {
    &.invoiceStatusCell {
      border-bottom: unset;
      width: 0.4375rem;
    }

    &.invoiceStatus:after {
      display: block;
      position: absolute;
      width: 0.5rem;
      content: "";
      height: 100%;
      top: -0.0625rem;
      border-right: 0.5rem solid $color-invoice-default;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    &.invoiceStatus-submitted::after {
      border-right-color: $color-border-invoice-status-submitted;
    }

    &.invoiceStatus-notsubmitted::after {
      border-right-color: $color-border-invoice-status-notsubmitted;
    }

    &.invoiceStatus-paid::after {
      border-right-color: $color-border-invoice-status-paid;
    }

    &.invoiceStatus-overdue::after {
      border-right-color: $color-border-invoice-status-overdue;
    }

    &.invoiceStatus-rejected::after {
      border-right-color: $color-border-invoice-status-rejected;
    }

    &.invoiceStatus-financed::after {
      border-right-color: $color-border-invoice-status-financed;
    }

    &.invoiceStatus-partiallypaid::after {
      border-right-color: $color-border-invoice-status-partiallypaid;
    }

    &.invoiceStatus-closed::after {
      border-right-color: $color-border-invoice-status-closed;
    }

    &.invoiceStatus-transferred::after {
      border-right-color: $color-border-invoice-status-transferred;
    }
  }

  .ant-checkbox-wrapper-disabled {
    display: none;
  }

  > div {
    margin: 0.9375rem 0;
    border-radius: 0.625rem;

    &.ContractFilter {
      padding: 0;
      margin-top: 1.175rem;
      cursor: pointer;
    }

    &.InvoiceListFilter {
      padding: 0.59375rem 4rem 4.70625rem 4rem;

      label {
        font-size: 0.6875rem;
      }

      input {
        margin: 0.2rem 0;
      }

      .ant-select-selector,
      .ant-picker,
      .ant-input-search {
        padding: 0;
        width: 100%;
      }

      .ant-picker-input {
        margin-top: 0.188rem;

        input {
          margin-bottom: 0.313rem;
        }
      }

      input {
        color: $color-labels-active;
      }

      .byNumberSearch {
        padding-top: 0.09rem;
      }

      .ant-select {
        width: 100%;
      }

      .ant-form-item,
      .ant-picker .ant-picker-input,
      .ant-select-selection-item {
        @include default-bottom-border;
        margin-right: 2rem;
      }

      .ant-select-selection-placeholder {
        opacity: 1;
      }

      .ant-form-horizontal > .ant-row:last-child .ant-select-selection-placeholder {
        @include default-bottom-border;
        margin-right: 2rem;
      }

      .ant-col.filled {
        .ant-form-item,
        .ant-picker .ant-picker-input,
        .ant-select-selection-item {
          border-bottom-color: $color-labels-active;
          color: $color-labels-active;
        }

        .ant-form-horizontal > .ant-row:last-child .ant-select-selection-placeholder {
          border-bottom-color: $color-labels-active;
          color: $color-labels-active;
        }
      }

      .ant-select-single .ant-select-arrow {
        margin-right: 1.3rem;
      }

      .ant-form-horizontal .ant-row:last-child .ant-select-single {
        margin-top: 0.09rem;
      }

      .ant-form-horizontal .ant-row:first-child .ant-col-6:first-child .ant-select-arrow {
        margin-right: -0.7rem;
      }

      button {
        margin-right: 2rem;
        margin-top: 0.8rem;
        padding: 0.625rem 1.25rem;
        border: 0.0625rem $color-sme-input-icons solid;
      }
    }

    .ant-select-open .ant-select-selection {
      box-shadow: unset;
    }

    .ant-table {
      tbody {
        > tr {
          td {
            .ant-btn.ant-btn-icon-only {
              padding: 0;
              display: inline-block;
              background-color: unset;
              width: auto;
              height: auto;
              border: unset;

              .anticon-safety {
                > svg {
                  width: 1.1875rem;
                  height: 1.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.invoiceUpload {
  .contractFilterSingle {
    .contractsSelectMenu {
      margin-top: -0.1875rem;
    }
  }

  .selectedContract {
    margin-top: 0.9375rem;
  }

  .ContractSelector,
  .selectedContract {
    color: $color-txt-black;
    cursor: default;

    h4 {
      font-size: 1.0625rem;
      font-weight: normal;
    }

    label {
      font-size: 0.9375rem;
    }
  }

  .selector-step-1 {
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;

    box-shadow: none;

    &.steps-content {
      padding-top: unset;
    }
  }

  .ContractSelector {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;

    .contractFilterSingle {
      @include box-shadow;

      .headerMenu {
        button {
          top: 0.8rem;
        }
      }
    }

    .contractsSelectMenu {
      top: 1rem;
    }
  }

  .steps-action {
    .uploadInvoiceDisclaimer {
      margin-left: auto;

      width: 18.75rem;
      text-align: right;
      margin-right: 1.25rem;
      margin-top: 0.25rem;
      color: $color-txt-black;
      font-size: 0.9375rem;
    }

    &.step-0 {
      text-align: right;
      margin-top: 0;
      padding: 0;
      padding-top: 2.5rem;
      margin-bottom: 20rem;
      border-bottom-left-radius: 0.625rem;
      border-bottom-right-radius: 0.625rem;
      box-shadow: none;
      flex-direction: row-reverse;
      justify-content: flex-start;

      button {
        line-height: 1rem;

        &:first-child {
          a {
            display: block;
            margin-top: -0.375rem;
          }
        }
      }
    }
  }

  .ant-form-item-label > label.ant-form-item-required::before {
    content: "";
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-selector {
    background-color: $color-background;
    border: 0 none;
  }

  .ant-input-group-addon {
    border-left: 0 none;

    .ant-select-selection-item {
      border: 0 none;
    }
  }

  .ant-input-number {
    width: 100%;
    border: 0 none;
  }

  .ant-upload-list-text > div {
    background-color: $color-background;
  }

  .ant-tag {
    padding: 0.5625rem 1rem;
    border-radius: 1.25rem;
    background: white;
    border: 0 none;

    margin-left: -1.25rem;
    margin-bottom: 0.625rem;
    margin-right: 1.875rem;

    .fileSize {
      text-transform: capitalize;
    }

    > span:not(:last-child) {
      min-width: 13.125rem;
      margin-right: 1.875rem;
      width: 100%;
      font-size: 0.9375rem;

      > span {
        float: right;
      }
    }

    .anticon-close {
      color: $color-close;
      margin-left: -0.5rem;
    }
  }

  .ant-affix {
    left: 0;

    .steps-action {
      background: $color-background;
      height: 8rem;
      padding-left: 25rem;
      padding-top: 1.25rem;
      justify-content: flex-start;

      .uploadInvoiceDisclaimer {
        margin-left: 20rem;
      }
    }
  }
  .ant-table {
    th:not(:first-child) {
      padding-left: unset;
    }
  }
  .ant-picker {
    padding: unset;
    width: 100%;
  }

  .ant-picker-input {
    border-bottom: 0 none;
  }

  .invoiceAttachment,
  .attachment {
    position: absolute;
    right: 0.3125rem;
  }
  .invoiceAttachment {
    width: 1.5rem;
    height: 1.5rem;
    background-color: $color-sme-input-icons;
    // -webkit-mask-image: url("./../icons/menu/icon_invoices.svg");
    // mask-image: url("./../icons/menu/icon_invoices.svg");
    mask-repeat: no-repeat;
    padding: 0;
    float: right;
  }

  .attachment {
    width: 1.5rem;
    height: 1.5rem;
    background-color: $color-sme-input-icons;

    // -webkit-mask-image: url("./../icons/icon_attachment.svg");
    // mask-image: url("./../icons/icon_attachment.svg");
    mask-repeat: no-repeat;
    padding: 0;
    float: right;
  }

  .ant-upload {
    width: 100%;

    span {
      display: inline-block;
    }
  }

  .ant-upload-list {
    width: 90%;
  }

  .invoiceUpload {
    > .steps-content {
      border-radius: 0.625rem;
    }
  }

  .steps-content {
    padding-top: 0.625rem;
  }

  .steps-action {
    margin-top: 1.5rem;
    display: flex;
    box-shadow: none;
    justify-content: flex-end;

    button:not(.back) {
      margin-left: 0.625rem;
      margin-bottom: 4.0625rem;
    }

    .back {
      margin-right: 1.25rem;
    }
  }

  .selectedContract {
    padding: 1rem;
  }

  .uploadDocuments {
    margin-top: 0.9375rem;
    padding-top: unset;
    padding-left: unset;
    padding-right: unset;
    padding-bottom: 1.25rem;

    .ant-tabs-tabpane {
      .block {
        &.uploadManually {
          padding-top: 1rem;
          padding-right: 0.125rem;
        }
      }
    }

    .ant-form .invoiceFiles .ant-form-item-control-input {
      border-bottom: 0 none;
      padding-right: unset;
    }

    .ant-tabs-tab {
      border-radius: 0.625rem;
      box-shadow: 0 0 3.6875rem -1.8rem rgba(0, 0, 0, 0.52);
      padding-top: 1.25rem;
      background-color: $color-tab-light;
      margin-right: unset;
      width: 128.7%;
      padding-left: 1.875rem;
    }

    .ant-col.filled .ant-form-item,
    .ant-col.filled .ant-picker .ant-picker-input,
    .ant-col.filled .ant-select-selection-item {
      border-bottom-color: $color-txt-black;
      color: $color-txt-black;
    }

    .ant-row:first-child {
      .ant-form-item-control-input {
        margin-top: 0.063rem;
      }
    }

    .ant-form-item,
    .ant-picker .ant-picker-input,
    .ant-select-selection-item {
      margin-right: 0.9375rem;
    }
    .ant-form {
      .ant-form-item-control-input {
        border-bottom: 0.0625rem solid $color-input-border-not-active;
        padding-right: 0.9375rem;

        &:focus-within {
          border-bottom-color: $color-labels-active;
        }
      }

      .ant-form-item-has-error {
        label {
          color: $color-error;
        }

        .ant-picker-focused,
        .ant-picker-input:focus,
        input:focus {
          box-shadow: unset;
          -webkit-box-shadow: unset;
        }

        .ant-form-item-control-input {
          border-color: $color-error;

          input,
          input::placeholder,
          .ant-picker input::placeholder,
          .ant-select-selection-placeholder,
          input:placeholder-shown,
          .ant-upload {
            color: $color-error;
          }
        }
      }
    }

    .uploadActions .ant-form-item-control-input {
      border: 0 none;
    }

    .ant-col.filled .ant-form-item,
    .ant-col.filled .ant-picker .ant-picker-input,
    .ant-col.filled .ant-select-selection-item {
      border-bottom-color: $color-txt-black;
      color: $color-txt-black;
    }

    .ant-upload {
      color: $color-txt-black;
    }

    .ant-select-selector {
      padding-left: unset;
    }

    .uploadActions {
      border-bottom: 0 none;
      margin-top: 1.5rem;
      margin-right: 1.875rem;
      float: right;

      &__clear-button {
        margin-right: 0.625rem;
      }
    }

    .uploadAutomatically {
      .ant-upload-drag {
        &.ant-upload-drag-hover {
          background-color: $color-report-checkbox-checked-bg;
        }
      }

      border-radius: 0;
      .descriptionWrapper {
        margin: 0 auto;
        width: 22.6875rem;
      }

      .ant-row {
        .ant-col:last-child {
          margin-left: 1.25rem;
        }
      }

      p:first-child {
        text-align: center;
        margin-top: 1.875rem;
        margin-bottom: 1.875rem;
      }

      .ant-upload-text {
        font-size: 1.3125rem;
        color: $color-sme-input-icons;
      }

      .ant-upload-hint {
        font-size: 0.9375rem;
        margin-bottom: 0.625rem;

        color: $color-txt-black;
      }

      .ant-upload-drag {
        border-color: $color-sme-input-icons;
        background: transparent;
        border-radius: 6.25rem;
        height: 15.5rem;
        margin-right: 1.875rem;
        width: auto;
      }

      .ant-upload-list {
        margin-top: 3.125rem;
      }

      .ant-upload-list-item:not(:last-child) {
        margin-top: 3rem;
      }
    }
  }

  .uploadSummary {
    margin-top: 0.9375rem;
    margin-left: 0.6rem;
    width: 97%;
    height: 97.2%;
    padding-top: 0.9375rem;
    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;
    color: $color-txt-black;
    font-size: 0.9375rem;

    .totalFees {
      button {
        padding: unset;
        height: unset;
      }

      .anticon-down,
      .anticon-up {
        margin-bottom: 0.3125rem;
        margin-left: 0.5rem;
      }

      .anticon-down,
      .anticon-up {
        background-image: url(/static/media/icon_arrow.5e1660f6.svg);
        width: 0.8115rem;
        height: 0.43625rem;

        background-repeat: no-repeat;

        svg {
          display: none;
        }
      }

      .anticon-up {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    div.totalFeesContainer {
      margin-top: auto;
      padding-left: 0;
      padding-top: 1rem;
      padding-bottom: 0;
      width: 100%;
    }

    .totalFeesItem {
      color: $color-labels-txt;
      font-size: 0.9375rem;
      line-height: 0.9375rem;
    }

    .feeItem {
      text-align: right;
    }

    h4,
    .ant-row {
      padding-left: 1.25rem;
    }

    h4 {
      margin-bottom: 1.875rem;
      font-size: 1.0625rem;
    }

    .ant-row {
      padding-bottom: 1.875rem;
      padding-top: 1.875rem;
    }

    .ant-row:not(:last-child) {
      border-bottom: 0.0625rem solid $color-separator;
    }
  }

  .uploadDocumentList {
    margin-top: 1rem;
    padding-top: unset;
    padding-left: unset;
    padding-right: unset;

    .invoicesSaved {
      margin: 0 2rem;
      padding-top: 1rem;
      font-size: 0.9375rem;
      color: $color-txt-black;

      &.hiden {
        display: none;
      }
    }

    .ant-table-wrapper {
      border-radius: 0.625rem;

      .invoiceDate,
      .invoiceDueDate,
      .buyerCode,
      .invoiceAmount {
        width: 7.5rem;
      }

      .transferClaim {
        width: 5.5rem;
      }

      .invoiceNumber {
        width: 8.5rem;
      }

      .buyer {
        width: 15rem;
        .overflow {
          width: 10rem;
          overflow: hidden;
          -ms-text-overflow: ellipsis;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .overflow:hover {
          overflow: visible;
        }

        .overflow:hover span {
          padding-right: 3rem;
          position: relative;
          background-color: $color-white-txt;
          z-index: 200;
        }
      }
    }

    .supportingFiles {
      padding-left: 2rem;
      padding-right: 2rem;

      .ant-tag {
        width: 100%;

        span:not(:last-child) {
          margin-right: 10%;
        }
      }

      .listWidth {
        width: 46%;
      }
      .listFiles {
        background-color: $color-background-block;
        border-radius: 0.625rem;
        padding-top: 1.2rem;
        padding-bottom: 1rem;
        padding-left: 3.625rem;

        > span > span:first-child {
          display: block;
          float: left;
          max-width: 23.188rem;
          white-space: break-spaces;
          margin-right: unset;
        }
      }

      .anticon-close {
        margin-top: 0.3125rem;
        svg {
          color: $color-close;
        }
      }

      p {
        font-size: 0.9375rem;
      }

      p {
        padding-left: 3.625rem;
      }
    }

    .additionalDocuments {
      padding-left: 9.375rem;
    }

    .ant-table-wrapper {
      padding-top: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 1rem;

      .ant-table-expanded-row-level-1 {
        .ant-row:first-child {
          font-size: 0.6875rem;
          color: #b0b0b0;
          font-weight: 500;
          margin-bottom: 0.625rem;
        }

        td {
          padding-left: 3.5625rem;
        }
      }

      .expand-parent td {
        border-bottom: 0 none;
      }

      .expand-parent td,
      .ant-table-expanded-row-level-1 td {
        background-color: $color-background-block;
      }

      .expand-parent td:first-child {
        border-top-left-radius: 0.625rem;
      }

      .expand-parent td:last-child {
        border-top-right-radius: 0.625rem;
      }

      .ant-table-expanded-row-level-1 td:first-child {
        border-bottom-left-radius: 0.625rem;
      }

      .ant-table-expanded-row-level-1 td:last-child {
        border-bottom-right-radius: 0.625rem;
      }
    }

    .anticon-down,
    .anticon-up {
      background-image: url(/static/media/icon_arrow.5e1660f6.svg);
      width: 0.8115rem;
      height: 0.43625rem;

      background-repeat: no-repeat;

      svg {
        display: none;
      }
    }

    .anticon-up {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    tr.error {
      background: $bg-invoice-status-overdue;
      border-radius: 1rem;
      height: 6.25rem;
      border-top: 0;
      border-bottom: 0;
      transform: scale(1);

      td.error-message {
        position: absolute;
        left: 4rem;
        top: 3.5rem;
        background-color: transparent;

        p {
          color: $color-upload-error;
          margin: 0;
        }
      }
    }

    tr.error td {
      background: $bg-invoice-status-overdue;
      padding-top: 0;
      padding-bottom: 3rem;
      border: 0;
    }
  }

  .invoice-verification {
    .ant-collapse-header {
      h3 {
        padding-left: 2.8125rem;
        font-weight: 400;
      }
    }

    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header[aria-expanded="false"] {
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
        }
      }
    }

    .invoice-verification__category {
      .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1.875rem;
      }

      .ant-collapse-header {
        padding-top: 1rem;

        .ant-col-9 {
          padding-left: 1.25rem;
        }
      }

      .invoice-verification__arrow-icon--active {
        transform: rotate(180deg);
      }

      .invoice-verification__arrow-icon {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .buyer-contract {
        .ant-form-item-label {
          > label::after {
            content: unset;
          }
        }

        .ant-col {
          display: block;
          width: 100%;
          flex: auto;
          margin-right: 0.625rem;
        }

        .ant-form-item-label {
          label {
            float: left;
            height: 0.8rem;
            font-size: 0.8125rem;
            color: $color-labels-txt;
          }
        }

        input,
        .ant-select {
          border-bottom: 0.0625rem solid $color-input-border-not-active;
          font-size: 0.9375rem;
          color: $color-txt-black;

          &:focus {
            border-bottom-color: $color-labels-active;
          }
        }

        .ant-select {
          input {
            border-bottom-color: transparent;
          }
        }
        .ant-input-group-addon {
          border: 0;
          background: transparent;
          padding-right: 1.25rem;
        }

        .ant-input-group-wrapper {
          margin-left: -1.25rem;

          .ant-input-group {
            input.ant-input {
              padding-left: unset;
            }
          }
        }
        .ant-input-group-wrapper {
          margin-left: unset;
        }

        .buyer-contact-edit,
        .buyer-contact-edit-action {
          margin-top: 0.625rem;
        }
        .buyer-contact-edit-action:first-child {
          margin-right: 0.625rem;
        }
      }

      .ant-collapse-header {
        .ant-row {
          padding-left: 3.125rem;
          font-size: 0.9375rem;
          align-items: center;

          .invoice-verification__category__invoice-number__header {
            color: $color-sme-input-icons;
            padding-left: 0.625rem;
          }

          .invoice-verification__category__total {
            text-align: right;

            &__header {
              padding-right: 2.5rem;
            }
          }
        }
      }

      .ant-select-selector {
        padding-left: unset;
      }

      .invoice-verification__category-heading {
        margin: 0;
      }

      .ant-collapse-content {
        padding-left: 6.4375rem;

        .invoice-verification__category__description {
          color: $color-labels-txt;
          font-size: 0.9375rem;
          margin-top: 2.5rem;
          margin-bottom: 3.125rem;
        }

        .invoice-verification__category__subtitle {
          font-size: 1.0625rem;
          margin-top: 1.25rem;
          margin-bottom: 0.375rem;
        }

        .invoice-verification__list {
          .ant-table-wrapper {
            padding-left: unset;
            padding-bottom: 1.5rem;
            padding-top: unset;

            .ant-table-cell {
              width: 5rem;
            }

            th:first-child {
              padding-left: unset;
            }

            tfoot {
              td {
                border-bottom: none;
                text-align: right;
                &:first-child {
                  padding-right: 0.625rem;
                }

                span:first-child {
                  padding-right: 2.5rem;
                }
              }
            }

            .invoiceAmount {
              padding-right: 0.625rem;
              text-align: right;
            }
          }
        }
      }

      &.ant-collapse-item.error:not(.ant-collapse-item-active) {
        background-color: $color-contract-overdue-bg;
      }

      .ant-form-item-has-error .ant-input,
      .ant-form-item-has-error .ant-input::placeholder,
      .ant-form-item-has-error .ant-select-selection-placeholder,
      .ant-form-item-has-error .ant-select,
      .ant-form-item-has-error .ant-input-number-input,
      .ant-form-item-has-error .ant-input-number-input::placeholder {
        color: $color-error;
        border-color: $color-error;
      }
    }

    &__button {
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1.0625rem;
      background-color: transparent;
      border: 0;
      padding: 1rem;
      opacity: 0.5;
      transition: opacity 0.3s ease-out;
      cursor: pointer;
      margin-right: 0.5rem;

      &--edit {
        margin-top: 0.625rem;
        background-image: url("../icons/icon_edit.svg");
      }
    }
  }
}

.uploadStatus {
  &.success {
    .ant-modal-footer {
      &::after {
        background-color: $color-txt-confirmed;
      }
    }

    .message {
      border-bottom: unset;
    }
  }

  .ant-modal-header {
    background-color: transparent;
  }
  .ant-modal-content {
    @include box-shaddow-thin;
    border-radius: 0.625rem;
  }

  &.errored {
    .message:last-child {
      border-bottom: none;
    }
  }

  .ant-row {
    padding-top: 1.875rem;
  }

  .invoiceValidationFooter {
    color: $color-txt-black;
    font-size: 1.0625rem;
  }

  .message {
    border-bottom: 0.0625rem solid $color-separator;

    p,
    div,
    span {
      font-size: 1.0625rem;
      color: $color-txt-black;
    }

    .ant-row {
      padding-top: unset;
    }
  }
  .message:last-child {
    padding-bottom: 0.875rem;
  }

  .error {
    color: $color-upload-error;
    margin-bottom: unset;
  }

  .ant-col-1 {
    margin-right: 0.625rem;
  }

  .ant-badge-count {
    padding-top: 0.125rem;
  }
}

.validationStatus {
  .invoiceNumberWrapper {
    padding-top: unset;
  }

  .message {
    > div:first-child {
      text-transform: capitalize;
    }
  }
}

.submitForFinancingStatus {
  &.success {
    .ant-modal-footer {
      padding-bottom: unset;

      &::after {
        background-color: $color-txt-confirmed;
      }

      > div {
        height: 0;
      }
    }
  }

  &.error {
    .reviewInvoiceSuccessMessage {
      margin-top: unset;
    }
  }

  .ant-modal-body {
    padding-left: 5.625rem;

    .ant-row {
      padding-top: 1rem;
      .ant-col {
        text-align: center;

        .anticon-check-circle {
          font-size: 8.1875rem;
          color: $color-txt-confirmed;
          opacity: 0.1;
        }

        .reviewInvoiceSuccessMessage {
          margin-top: 2.375rem;
        }

        .reviewInvoiceSuccessMessage,
        .thankYouForChoosingMessage {
          font-size: 1.5625rem;
        }

        .thankYouForChoosingMessage {
          line-height: 1.3;
        }

        .closeLink {
          font-size: 0.9375rem;
          color: $color-sme-blue;
          margin-top: 3.125rem;
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

.automaticUploadStatus {
  .automaticUploadInvoiceValidationErrorFooter {
    font-size: 1.0625rem;
    color: $color-txt-black;
  }

  .automaticUploadStatus-invoiceNumber {
    margin-left: 1rem;
  }

  .ant-modal-content .ant-modal-footer {
    padding-bottom: 6.5625rem;
  }

  .ant-row {
    padding-right: 1.15rem;
  }

  .incorrectInvoices {
    margin-bottom: 0.625rem;
  }
}

.onlySelectedContract {
  font-size: 0.9375rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

body .ant-tabs-tabpane {
  .ant-input__placeholder {
    color: blue;
  }

  .ant-select-selection,
  input {
    :placeholder-shown {
      color: $color-txt-black;
    }
    &:placeholder-shown {
      color: $color-txt-black;
    }
    ::placeholder {
      color: $color-txt-black;
    }
    color: $color-txt-black;
    &::-webkit-input-placeholder {
      color: $color-txt-black;
    }
    &:placeholder {
      color: $color-txt-black;
    }
    :placeholder {
      color: $color-txt-black;
    }
    ::-webkit-input-placeholder {
      color: $color-txt-black;
    }
  }

  ::-webkit-input-placeholder {
    color: $color-txt-black;
  }

  .ant-select-selection-placeholder {
    opacity: 1;
  }

  .ant-picker .ant-picker-input {
    border: 0 none;
  }

  .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
  }

  input,
  .ant-input,
  .ant-select-selection-placeholder {
    color: $color-txt-black;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $color-txt-black;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-txt-black;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-txt-black;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-search {
    left: 0;
  }

  .ant-form-item-label {
    padding-bottom: 0;

    label {
      font-size: 0.6875rem;
      color: $color-labels-txt;
      height: 0.6875rem;
    }
  }

  .ant-picker {
    color: $color-txt-black;

    input {
      color: $color-txt-black;

      ::-webkit-input-placeholder {
        /* Edge */
        color: $color-txt-black;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color-txt-black;
      }

      ::placeholder {
        color: $color-txt-black;
      }
    }

    .ant-picker-suffix {
      position: absolute;
      right: -0.9375rem;
    }
  }

  .ant-select-selector {
    right: 0;
    padding-right: 0;
  }

  .ant-select-arrow {
    right: 0;

    svg {
      display: none;
    }
  }

  .anticon-search {
    transform: rotate(180deg);
    background-image: url(/static/media/icon_arrow.5e1660f6.svg);
    width: 0.8115rem;
    height: 0.43625rem;

    background-repeat: no-repeat;
  }
}

.penaltyTwo {
  margin-left: -0.9375rem;
}

.invoiceBrief {
  .type-name {
    text-transform: capitalize;
  }

  h2 {
    margin-bottom: 1.125rem;
    font-weight: 400;
  }

  .invoiceStatus {
    margin-left: 1rem;
    font-size: 1.3125rem;
    font-weight: normal;
    color: $color-txt-black;
  }

  .limit-col {
    padding-left: 0.375rem;
  }

  label {
    font-size: 0.8125rem;
    color: $color-labels-txt;
  }

  p {
    color: $color-txt-black;
    font-size: 0.9375rem;
    margin-bottom: 0;
  }
}
.reportTable,
.tableBelowWave {
  &:not(.tableBelowWave) {
    border-radius: 0.625rem;
  }

  .ant-table {
    thead {
      > tr {
        > th {
          padding-left: unset;
        }
      }
    }

    tr.expand-parent td {
      border-bottom: none;
    }

    td.ant-table-row-expand-icon-cell,
    th.ant-table-row-expand-icon-cell {
      border: none;
      width: $table-table-wrapper-padding;
      max-width: $table-table-wrapper-padding;
      min-width: $table-table-wrapper-padding;
    }
  }
}

.invoiceFiles,
.supportingDocuments {
  .ant-upload {
    display: block;
    padding-bottom: 0.0625rem;
  }
  .ant-upload-list {
    height: auto;
    border-top: 0.0625rem solid $color-input-border-not-active;
    width: 100%;
    margin-top: 0.1875rem;
  }
  .ant-form-item-control-input {
    padding-top: 0.1875rem;
  }

  .ant-btn.ant-upload-list-item-card-actions-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only {
    opacity: 1;
  }
}

.ant-input-number-focused {
  box-shadow: none;
}

.ant-form-item-has-error {
  border: 0 none;
}

.supportingDocumentsDragger {
  .ant-btn.ant-upload-list-item-card-actions-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only {
    opacity: 1;
  }
}

.edit-invoice-modal {
  .ant-modal-body {
    padding: 2rem 7.5rem;
  }

  .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
    margin-right: 6.6rem;
  }

  .ant-modal-footer::after {
    content: none;
  }
}

.edit-invoice-form {
  .ant-form-item {
    margin-right: 0.9375rem;
  }

  .ant-form-item-control-input {
    border-bottom: 0.0625rem solid $color-input-border-not-active;
    padding-right: 0.9375rem;
    font-size: 0.8125rem;

    input {
      font-size: 0.8125rem;
    }
  }

  .ant-select-selection-placeholder {
    font-size: 0.8125rem;
  }

  .ant-upload {
    font-size: 0.8125rem;
    color: $color-txt-black;
  }

  .currency {
    margin-right: unset;

    .ant-select-arrow {
      display: none;
    }

    .ant-select-selector {
      margin-top: -0.0625rem;
      padding: 0;
    }

    .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background: transparent;
    }

    .ant-col.ant-form-item-label {
      max-width: none;

      label {
        width: 15rem;
      }
    }
  }

  .ant-select-selection-placeholder {
    opacity: 1;
  }

  .ant-picker {
    padding: unset;
    width: 100%;

    .ant-picker-suffix {
      color: $color-sme-input-icons;
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;

    label {
      font-size: 0.8125rem;
      color: $color-labels-txt;
      height: 0.6875rem;
    }
  }

  .ant-picker .ant-picker-input,
  .ant-input {
    border-bottom: unset;
  }
}

.invoice-verification-status {
  .ant-modal-content {
    @include box-shaddow-thin;
    border-radius: 0.625rem;

    .ant-modal-header {
      background-color: transparent;
    }
    .ant-modal-body {
      padding-left: 5.625rem;

      .ant-row {
        padding-top: 1rem;

        .ant-col {
          text-align: center;

          .invoice-verification-status__icon {
            font-size: 8.1875rem;
            color: $color-txt-confirmed;
            opacity: 0.1;
          }

          .invoice-verification-status__thanks-text {
            margin-top: 2.375rem;
            line-height: 1.3;
          }

          .invoice-verification-status__thanks-text {
            font-size: 1.5625rem;
          }

          .invoice-verification-status__close {
            margin-top: 3.125rem;
            margin-bottom: 2.5rem;
            font-size: 0.9375rem;
            color: $color-sme-blue;
          }
        }
      }
    }

    .ant-modal-footer {
      padding-bottom: unset;

      &::after {
        background-color: $color-txt-confirmed;
      }

      .ant-btn[disabled] {
        visibility: hidden;
      }
    }
  }
}
