body .loginForm {
  @include box-shadow;

  h1 {
    margin-left: unset;
    font-size: 1.5625rem;
  }

  .ant-form-item-label > label::after {
    content: "";
  }

  .login-form-button {
    margin-top: 3.75rem;
  }

  padding-top: 3.5625rem;
  padding-bottom: 3.5625rem;
  padding-right: 30%;

  .form-wrapper {
    min-width: 28.125rem;
    width: 28.125rem;
    max-width: 28.125rem;
    margin: 0 auto;
  }

  input {
    font-size: 0.9375rem;

    &.ant-input#sms_login_password {
      padding-top: 4px;
      padding-bottom: 4px;
    }

    &::placeholder {
      color: $color-txt-black;
    }
  }

  .ant-form-item {
    min-width: 28.125rem;
    width: 28.125rem;

    &.ant-form-item-has-error {
      .ant-input {
        color: $color-error;
        border-color: $color-error;
      }

      label {
        color: $color-error;
      }
    }

    &.minimal-bmargin {
      margin-bottom: 0.6rem;
    }

    > .ant-col {
      display: block;
      width: 100%;
      flex: auto;

      label {
        float: left;
      }
    }

    .ant-input-affix-wrapper {
      padding: unset;
      outline: none;
      box-shadow: none;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .ant-input-suffix {
      position: absolute;
      right: 0;
    }
  }

  .ant-form-item:last-child {
    text-align: right;
    margin-bottom: 0;
  }

  .ant-form-item-required {
    height: 0.6875rem;
    &::before {
      display: none;
    }

    font-size: 0.8125rem;
    color: $color-labels-txt;
  }

  .login-form-forgot {
    padding-right: unset;
    font-size: 0.9375rem;
    color: $color-sme-blue;
  }

  #smartid_login {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ant-form-item {
      min-width: auto;

      div.ant-col.ant-form-item-label {
        padding: 0;
      }
    }

    .phoneCode {
      width: 5.625rem;
    }
  }

  .ant-select {
    margin-right: 0.625rem;
    border-bottom: 0.0625rem solid $color-input-border-not-active;
    height: 2.0625rem;
    color: $color-txt-black;

    &.phoneCode {
      height: 2rem;
    }

    .ant-select-selection-placeholder {
      color: $color-txt-black;
      opacity: 1;
    }
  }

  .ant-select-focused {
    border-bottom-color: $color-labels-active;
  }

  .ant-input-group-addon {
    border: 0 none;
    background: transparent;
    padding-top: 0.125rem;
    padding-right: 0;
  }

  .ant-select-selector {
    padding-left: 0;
    font-size: 0.9375rem;
  }

  .phoneNumber {
    margin-top: 0.875rem;
  }

  //tabs start.
  .loginTabs {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;

    button {
      margin-right: 0.625rem;
    }
  }

  //tabs end.

  .smartIdConfirm,
  .smartIdConfirm label {
    color: $color-txt-black;
  }

  .smartIdConfirm {
    clear: both;
    label {
      font-size: 0.9375rem;
    }

    p {
      font-size: 1.125rem;
    }

    > span {
      display: block;
      font-size: 3.0625rem;
    }

    button {
      margin-top: 2.6rem;
    }

    .ant-btn-link {
      padding-left: 0;
    }

    .ant-btn-primary {
      float: right;
    }
  }
}

#sms_login .login-form-button {
  margin-top: 3.2rem;

  &:nth-child(2) {
    margin-left: 0.625rem;
  }
}

body .resetPassword {
  p {
    width: 15.625rem;
    margin-bottom: 4.9375rem;
    font-size: 0.9375rem;
  }

  .ant-form-item-label > label::after {
    content: "";
  }

  .ant-btn {
    margin-top: 2.5rem;
    margin-left: 0.625rem;
    font-size: 1.1rem;
    line-height: 1;
  }

  .ant-btn:not(.ant-btn-primary) {
    color: $color-cancel-button;
    border-color: $color-cancel-button;
  }
}

body .resetPasswordConfirm {
  p {
    width: 21.625rem;
    margin-bottom: 4.9375rem;
  }

  .ant-form-item-extra {
    margin-top: 0.625rem;

    span {
      color: $color-link;
    }
  }

  .ant-form-item-label > label::after {
    content: "";
  }

  .ant-btn {
    margin-top: 2.5rem;
    margin-left: 0.625rem;
  }

  .ant-btn:not(.ant-btn-primary) {
    color: $color-cancel-button;
    border-color: $color-cancel-button;
  }
}

#sms_login_confirm {
  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-btn-link {
    font-size: 1.0625rem;
  }

  .ant-btn-primary {
    margin-top: 1.875rem;
  }

  label::after {
    content: "";
  }
}

body .registerForm {
  position: absolute;
  top: 0;
  right: 0;
  background: $color-login-tab-active;
  height: 100%;
  width: 17.375rem;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin-left: 0;
  }

  h1 {
    padding-top: 1.125rem;
    font-size: 1.5625rem;
    margin-left: 0;
  }
  button {
    position: absolute;
    bottom: 3.5625rem;
    color: $color-login-tab;
    border-color: $color-login-tab;
    background-color: transparent;

    span {
      font-size: 1.1rem;
      line-height: 1;
    }
  }
}
