.select {
  margin-right: 0.625rem;
  height: 2.0625rem;
  color: $color-txt-black;

  @include default-bottom-border;

  &.phoneCode {
    height: 2rem;
  }

  .ant-select-selection-placeholder {
    color: $color-txt-black;
    opacity: 1;
  }
}

.ant-select-selector {
  border-top: unset;
  border-left: unset;
  border-right: unset;

  outline: unset;
  box-shadow: unset;
  -webkit-box-shadow: unset;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input) {
  .ant-select-selector {
    box-shadow: unset;
    -webkit-box-shadow: unset;
  }
}

.ant-select-focused {
  border-bottom-color: $color-labels-active;
}

.ant-form-item-has-error {
  div.custom-select {
    & div.ant-select {
      border-color: $color-error;

      .ant-select-selection-placeholder {
        color: $color-error;
      }
    }
  }
}

.custom-select {
  & div.ant-select {
    width: 100%;
    border-bottom: 0.0625rem solid $color-input-border-not-active;

    &--active {
      border-bottom-color: $color-labels-active;
      color: $color-labels-active;
      outline: none;
      box-shadow: none;
    }

    &.ant-select-single {
      .ant-select-arrow img {
        transition: transform 0.3s, -webkit-transform 0.3s;
      }
      &.ant-select-open .ant-select-arrow img {
        transform: rotate(180deg);
      }

      div.ant-select-selector {
        padding: 0;
        height: 1.938rem;

        .ant-select-selection-search {
          left: 0;

          .ant-select-selection-search-input {
            font-size: 0.9375rem;
            line-height: 1.5715;
            padding-left: 0;
          }
        }
      }
    }

    &.ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      background: transparent;
    }

    .ant-select-selection-placeholder {
      font-size: 0.875rem;
      line-height: 1.5715;
      color: $color-input-border-not-active;
      opacity: 1;
    }
  }
}
