.radio-group {
  &--go4rent-theme {
    .ant-radio-wrapper {
      &:hover .ant-radio,
      .ant-radio:hover .ant-radio-inner,
      .ant-radio-input:focus + .ant-radio-inner,
      .ant-radio-checked::after {
        border-color: $color-g4r-red;
      }

      &.ant-radio-wrapper-checked {
        .ant-radio-inner {
          border-color: $color-g4r-red;

          &::after {
            background-color: $color-g4r-red;
          }
        }
      }
    }
  }

  label.ant-radio-wrapper {
    font-size: 0.9375rem;
    color: $color-labels-active;
  }
}
