.commercial-proposal {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // min-height: 100vh;
  // background-color: #f3f4f6;
  // width: 100%;

  h2{
    text-align: left;
    font-weight: 500;
    font-size: 1.25rem;

  }

  .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: none;
  }

  .ant-form-item {
    // margin-bottom: 10px !important;
    /* Adjust value as needed */
  }

  .form-wrapper {
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1024px;
  }


  .form-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
  }


  .upload-button-container{
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    margin-bottom: 2rem;
    .upload-button{
      border-color: #db435c;
      color: #db435c;
      cursor: pointer;
    }

  }





  .client-container {

    // display: grid;
    // grid-template-columns: auto auto;
    // justify-content: space-between;
    .client-contacts {}
  }


  .gray-placeholder::placeholder, .ant-picker-input::placeholder {
    color: #a0a0a0;
    /* Gray color */
  }




  .car-container {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .ant-form-item-label>label::after {
      content: none;
    }
  }

  // .car-container{
  //   display: grid;
  //   grid-template-columns: auto auto;
  //   justify-content: space-between;
  //   margin-top: 2rem;
  //   margin-bottom: 4rem;
  //   .car-container-papildoma-info{
  //       grid-column: span 2;
  //       .ant-row{
  //           flex-direction: column;
  //           .ant-form-item-label{
  //               text-align: left;
  //           }
  //       }
  //   }

  // }


  .pardavejo-rekvizitai-container{
      margin-bottom: 2rem;
  }

  // .ikelkite-nuotraukas-container{
  //   margin-bottom: 4rem;
  // }

  .submit-button-container {
    display: flex;
    justify-content: left;
    width: 100%;

    .spinner-container {
      display: flex;
      gap: 1rem;

    }

    .submit-button {
      width: 100%;
      min-width: 200px;
      padding-left: 1rem;
      padding-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #007bff;
      color: white;
      border: none;
      padding: 10px 16px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 5px;
      transition: background 0.3s ease;
    }

    .submit-button:disabled {
      background-color: #5a9be6;
      cursor: not-allowed;
    }

    .spinner {
      width: 16px;
      height: 16px;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-top: 2px solid white;
      border-radius: 50%;
      animation: spin 0.8s linear infinite;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .button-how-to-take-img{
    margin-bottom: 2rem;
    cursor: pointer;
  }


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
  }
  
  .modal-content {
    position: relative;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: black;
  }
  
  .close-button:hover {
    color: white;
  }
  
  /* Header */
  .modal-header {
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: center;
    background-color: #ed2f59;
    .header-text {
      color:white;
      display: flex;
      justify-content: space-between;
      max-width: 300px;
      .header-text-description{
        text-align: center;
      }
    }


  }
  
  .header-image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  

  
  /* Grid Layout */
  .modal-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  .grid-item {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  
  .grid-image {
    width: 100%;
    height: auto;
    // margin-bottom: 1rem;
    object-fit: cover;
  }
  
  .grid-text {
    flex: 1;
    font-size: 14px;
  }

  .modal-grid-scrollable {
    max-height: 60vh; /* Adjust based on your preference */
    overflow-y: auto;
    max-width: 900px;
    padding: 20px;
  }
  
  .modal-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
  
  @media (min-width: 768px) {
    .modal-grid {
      grid-template-columns: 1fr 1fr;
    }
    .grid-item {
      grid-template-columns: 1fr 1fr;
    }
    .grid-image {
      max-width: 200px;
      height: auto;
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
  
  

  

  /* Modal Overlay */
.status-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.status-modal-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  text-align: center;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal Header */
.status-modal-header {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

/* Icons */
.status-modal-icon {
  width: 48px;
  height: 48px;
}

.status-modal-success-icon {
  color: green;
}

.status-modal-error-icon {
  color: red;
}

/* Modal Message */
.status-modal-message {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

/* Close Button */
.status-modal-button {
  // width: 100%;
  // padding: 10px;
  // background: #007bff;
  // color: white;
  // border: none;
  // border-radius: 4px;
  // cursor: pointer;
  // font-size: 16px;
}

.status-modal-button:hover {
  // background: #0056b3;
}

.company-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90vw;
}

.company-modal-content .title-pasirinkite-imone {
text-align: left;

}

.company-search-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.company-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.company-search-button {
  // background: #007bff;
  // color: white;
  // border: none;
  // padding: 8px 12px;
  // border-radius: 4px;
  // cursor: pointer;
}

.company-search-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.company-error {
  margin: 10px 0;
  text-align: left;
  // color: red;
}

.company-loading {
  margin: 10px 0;
  text-align: center;
}

.company-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.company-table th,
.company-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.company-table th {
  background: #f4f4f4;
}



.company-table-row {
  cursor: pointer;
}

.company-table-row:hover {
  background: #f0f0f0;
}

.company-close-button {
  margin-top: 20px;
  // background: #dc3545;
  // color: white;
  // border: none;
  // padding: 8px 12px;
  // border-radius: 4px;
  // cursor: pointer;
}

.ant-form-item-margin-offset{
  margin-bottom: -10px;
}

.date-picker{
  width: 100%;
}


}