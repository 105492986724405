.userTopMenu {
  padding-right: 3.8rem;
  justify-content: flex-end;
  display: flex;

  > * {
    margin-left: 2rem;
  }
}

.BurgerMenu {
  width: 14.8125rem;
  // background-image: url("./../images/burger.png");
  background-repeat: no-repeat;
  background-position: right 1rem;
  z-index: 3;

  .BurgerMenuFace {
    font-size: 0.9375rem;
    letter-spacing: 0;
    cursor: pointer;

    p {
      font-size: inherit;
      margin-bottom: 0;
    }
  }

  .BurgerHeader {
    padding: 1rem;
    color: $color-txt-black;

    p {
      font-size: 0.9375rem;
      margin-bottom: 0;
    }
  }

  .BurgerMenuContent {
    display: none;
    background-color: $color-background;
    z-index: 1;
    top: 0;
    border-radius: 0.625rem;
    width: 100%;
    margin-top: -1rem;
    margin-left: -1rem;
  }

  &.active-true {
    .BurgerMenuContent {
      font-size: 0.9375rem;
      position: absolute;
      display: block;
      width: 17rem;
      @include box-shaddow-thin;
      z-index: 2;

      .BurgerHeader {
        // background-image: url("./../images/burger-grey.png");
        background-repeat: no-repeat;
        background-position: 15.55rem 2rem;
        padding-bottom: 0;

        p {
          font-size: inherit;
          margin-bottom: 0;

          &:last-child {
            padding-bottom: 1rem;
          }
        }
      }

      .BurgerContent {
        ul {
          padding: 0;

          li {
            list-style: none;
            padding: 1.3rem 1rem;
            background-color: $color-background-block;
            font-size: 0.8125rem;
            @include default-bottom-border;
            border-color: $color-border-user-menu;
            color: $color-txt-black;
            cursor: pointer;

            &:first-child {
              @include border-top;
              border-color: $color-border-user-menu;
            }

            &:hover {
              color: $color-txt-black;
              background-color: $color-background;
            }
          }
        }
      }

      .BurgerFooter {
        padding: 1.3rem 1rem;

        > div {
          width: 50%;
          display: inline-block;

          &:last-child {
            text-align: right;
          }

          button {
            background-color: unset;
            box-shadow: unset;
            border: unset;
            outline: unset;
            color: $color-txt-user-menu-btn;
            padding: 0;
            font-size: 0.8125rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.App-header {
  .ant-row {
    padding: 0;
  }
}
