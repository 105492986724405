.ant-picker {
  &.datepicker {
    width: 100%;
  }

  &.ant-picker-focused {
    border-bottom-color: $color-txt-black;
  }
}

.ant-picker {
  border: 0 none;

  .ant-picker-input {
    border-bottom: 0.0625rem solid $color-input-border-not-active;
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border-bottom-color: $color-txt-black;
}

.ant-picker-focused {
  border-bottom-color: $color-labels-active;
}

.ant-picker {
  input,
  input::placeholder {
    color: $color-txt-black;
  }
}
