.DonutPie {
  padding: 0.6rem;
  background-color: $color-background;
  margin-bottom: 0.9375rem;
  border-radius: 0.625rem;

  .legend-cont {
    &.active {
      &.total {
        background-color: $bg-donut-total;
      }
      &.used {
        background-color: $bg-donut-used;
      }
      &.available {
        background-color: $bg-donut-avl;
      }
      &.overdue {
        background-color: $bg-donut-overdue;
      }

      .ant-col {
        background-color: transparent;
      }
    }
  }

  .legend-blur {
    background-color: $bg-donut-shaded;
    opacity: 0.45;
  }

  .plate {
    background-color: $color-donut-bg;
    top: 2.093rem;
    position: absolute;
    width: 12.5rem;
    height: 12.5rem;
    left: 24.594rem;
    -webkit-border-radius: 6.75rem;
    -moz-border-radius: 6.75rem;
    border-radius: 6.75rem;
  }

  .plate + svg {
    position: absolute;
  }

  .donut-wrapper {
    width: 16.7rem;
    height: 16.7rem;
    position: absolute;
    top: 0;
    left: 22.5rem;
  }

  .VictoryContainer {
    text {
      display: none;
    }
  }

  .ant-row {
    margin-bottom: 0;
  }

  .donutData {
    color: $color-txt-black;

    &:last-child {
      > .ant-col {
        padding-top: 0;
      }
    }
    > .ant-col {
      padding: 1.5rem;
      max-width: 30.04rem;
      margin: 0.1rem;
      border-radius: 0.625rem;

      &:last-child {
        h4 {
          float: right;
        }

        p {
          text-align: right;
        }

        span,
        a {
          float: left;
        }
      }
    }

    > .ant-col:first-child {
      padding-right: 8rem;
    }

    > .ant-col:last-child {
      padding-left: 8rem;
    }

    > .ant-col > .ant-row > .ant-col {
      padding: 0;

      &.ant-col-15 {
        padding-top: 0.4375rem;
      }
    }

    .ant-col {
      background-color: $color-donut-bg;

      h4 {
        font-size: 0.9375rem;
        margin: 0;
      }

      span {
        float: right;
        font-size: 1.5625rem;
        color: inherit;
        margin-top: -0.4rem;
      }

      p {
        font-size: 0.8125rem;
        margin: 0;
        padding: 0;
        color: $color-donut-p;
        line-height: 1rem;
      }

      a {
        float: right;
        font-size: 0.8125rem;
        color: $color-sme-input-icons;
        margin-top: 1.3rem;
      }
    }
  }
}
