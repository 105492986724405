.left-menu-wrapper {
  background: inherit;
  margin-left: 3.9375rem;
  padding-top: 2rem;
  width: 18.75rem;

  .sideNav {
    a {
      font-size: 0.9375rem;
      display: block;
      padding-bottom: 2rem;

      &::before {
        display: inline-block;
        content: "";
        width: 1.375rem;
        height: 1.375rem;
        margin-right: 1.5rem;

        background-color: $color-menu-item-not-active;
        // -webkit-mask-image: url("./../../../icons/menu/icon_dash.svg");
        // mask-image: url("./../../../icons/menu/icon_dash.svg");
        mask-repeat: no-repeat;
      }

      span {
        position: relative;
      }

      &.menu-loan {
        span {
          top: -0.1rem;
        }
      }

      &.menu-limit,
      &.menu-leasing,
      &.menu-factoring {
        span {
          top: -0.3rem;
        }
      }

      // &.menu-loan::before {
      //   -webkit-mask-image: url("./../../../icons/menu/icon_card.svg");
      //   mask-image: url("./../../../icons/menu/icon_card.svg");
      //   width: 22px;
      //   height: 15px;
      // }

      // &.menu-limit::before {
      //   -webkit-mask-image: url("./../../../icons/menu/icon_lock.svg");
      //   mask-image: url("./../../../icons/menu/icon_lock.svg");
      //   width: 22px;
      //   height: 22px;
      // }

      // &.menu-leasing::before {
      //   -webkit-mask-image: url("./../../../icons/menu/icon_key.svg");
      //   mask-image: url("./../../../icons/menu/icon_key.svg");
      //   width: 20px;
      //   height: 18px;
      // }

      // &.menu-factoring::before {
      //   -webkit-mask-image: url("./../../../icons/menu/icon_percent.svg");
      //   mask-image: url("./../../../icons/menu/icon_percent.svg");
      //   width: 16px;
      //   height: 21px;
      // }

      &.nav-active,
      &:hover {
        color: $color-sme-input-icons;

        &::before {
          background-color: $color-sme-input-icons;
        }
      }
    }

    .nav-active {
      pointer-events: none;
    }
  }

  .personal-assistance {
    margin-top: 18.75rem;
    max-width: 8.75rem;

    .personal-assistance__icon {
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $color-sme-input-icons;
      // -webkit-mask-image: url("./../../../icons/menu/icon_info.svg");
      // mask-image: url("./../../../icons/menu/icon_info.svg");
      mask-repeat: no-repeat;
      margin-bottom: -0.3125rem;
    }
    img {
      border-radius: 50%;
      width: 3.75rem;
      height: 3.75rem;
      margin-bottom: 0.625rem;
      margin-top: 1.25rem;
    }

    p {
      font-size: 0.9375rem;
      margin-bottom: 0.3rem;
      word-break: break-word;
    }
  }
}
